import { SSL_OP_TLS_ROLLBACK_BUG } from 'constants';
import { Action, Reducer } from 'redux';
import { KnownAction, SalesState, Product, SaleDifference, LinkSaleDetailContainer, MeLiSaleDetailContainer, MeLiOrder, OrderStatus, Sales } from '../types';
 
const unloadedState: SalesState = {
    orders: [],
    salesCreatedSuccessfully: false,
    salesBilledSuccessfully: false,
    sales: [],
    products: [],
    allProducts: [],
    emptyResults: false,
    emptySalesResults: false,
    amountSalesCreated: 0,
    currentSale: null,
    lastSaleCreated: 0,
    messages: [],
    productRefreshed: false,
    confirmedSuccess: false,
    confirmedReopen: false,
    amountSalesBilled: 0,
    cleanMessage: false,
    csvResult: {},
    packers: [],
    packedSucess: false,
    messageConfigurations: [],
    messageConfigurationUpdateSuccess: false,
    saleShippedSuccess: false,
    auditLogs: [],
    amountOrderConfirmed: 0,
    messageStockConfirmedSuccess: false,
    saleDeletedSuccess: false,
    customMessage: '',
    drivers: [],
    customMessagePopUp: '',
    salesToPrint: null,
    //Back 89
    ContainsMessages: 0,
    //BACK 145
    provinces: [],
    cities: [],
    companies: [],
    printOk: false,
    loadedWitouthDifference: false,
    //BACK 287
    confirmedBackReviewSuccess: false,
    DuplicateSales: 0,
    //BACK-293
    logistics: [],
    //BACK
    receiveSalesSuccessfully: false,
    countConfirmedSalesWithOutMessage: 0,
    logisticPrices: [],
    printTagsMassiveSuccess: false
};

export const reducer: Reducer<SalesState> = (state: SalesState | undefined, incomingAction: Action): SalesState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'RECEIVE_MELI_ORDER':
            return { ...state, orders: action.orders, emptyResults: action.orders.length == 0 };
        case 'RECEIVE_SALES_CREATED':
            return { ...state, salesCreatedSuccessfully: true, amountSalesCreated: action.amountSalesCreated, lastSaleCreated: action.saleId, ContainsMessages: action.containsmessages, loadedWitouthDifference: action.loadedWitouthDifference, DuplicateSales: action.duplicatesales};
        case "DISMISS_LOADED_WITHOUT_DIFF":
            return { ...state, loadedWitouthDifference: false};
        case 'DISMISS_SALES_CREATED':
            return { ...state, salesCreatedSuccessfully: false, orders: [] };
        case 'CLEAN_ORDERS':
            return { ...state, emptyResults: false, orders: [] };
        case 'CLEAN_CURRENT_SALE':
            return { ...state, emptyResults: false, currentSale: null };
        case 'RECEIVE_SALES':
            return { ...state, sales: action.sales, emptySalesResults: action.sales.length == 0 };
        case 'RECEIVE_PRODUCTS':
            //let activeProducts = action.products.filter((p: Product) => p.isActive);
            return { ...state, products: action.products, allProducts: action.products };
        case 'RECEIVE_CSV_RESULT':
            return { ...state, csvResult: action.result };
        case 'RECEIVE_SALE':
            return { ...state, currentSale: action.sale };
        case 'RECEIVE_SALES_ORDER_ADDED':
            return { ...state, salesCreatedSuccessfully: true, amountSalesCreated: 0, currentSale: null }
        case 'RECEIVE_MESSAGES':
            return { ...state, messages: action.messages }
        case 'RECEIVE_PRODUCT_REFRESH':
            let newProducts = state.products.map((product: Product) => {
                if (product.id == action.product.id) {
                    return action.product;
                }
                return product;
            });
            return { ...state, products: newProducts, productRefreshed: true };
        case 'DISMISS_PRODUCT_REFRESH':
            return { ...state, productRefreshed: false };
        case 'RECEIVE_CONFIRMED_SUCCESS':
            let newCurrentSale = state.currentSale;
            if (newCurrentSale != null) {
                newCurrentSale.state = action.newState;
            }
            return { ...state, confirmedSuccess: true, currentSale: newCurrentSale };
        case 'DISMISS_CONFIRMED_SUCCESS':
            return { ...state, confirmedSuccess: false };
        //BACK 287
        case 'RECEIVE_CONFIRMED_BACK_REVIEW_SUCCESS':
            return { ...state, confirmedBackReviewSuccess: true };
        case 'DISMISS_CONFIRMED_BACK_REVIEW_SUCCESS':
            return { ...state, confirmedBackReviewSuccess: false };
        case 'RECEIVE_SALES_BILLED':
            return { ...state, salesBilledSuccessfully: true, amountSalesBilled: action.amountSalesBilled };
        case 'DISMISS_SALES_BILLED':
            return { ...state, salesBilledSuccessfully: false, amountSalesBilled: 0 };
        case 'REFRESH_PAYMENT_STATUS':
            if (state.currentSale != null) {
                let newSaleStatus = { ...state.currentSale };
                if (action.paymentType == 'difference') {
                    let diff = newSaleStatus.differences.find((difference: SaleDifference) => difference.id == action.id);
                    if (diff != undefined) {
                        diff.isPayed = true;
                    }
                }
                else {
                    let diff = newSaleStatus.linkSaleDetails.find((difference: LinkSaleDetailContainer) => difference.id == action.id);

                    if (diff != undefined) {
                        diff.isPayed = true;
                    }
                }
                return { ...state, currentSale: newSaleStatus };
            }
        case 'RECEIVE_MESSAGE_SENT':
            return { ...state, cleanMessage: true };
        case 'DISMISS_MESSAGE_SENT':
            return { ...state, cleanMessage: false };
        case 'RECEIVE_PACKERS':
            return { ...state, packers: action.packers };
        case 'RECEIVE_DRIVERS':
            return { ...state, drivers: action.drivers };
        //BACK-193
        case 'RECEIVE_LOGISTICS':
            return { ...state, logistics: action.logistics };
        //BACK 145
        case 'RECEIVE_PROVINCES':
            return { ...state, provinces: action.provinces };
        case 'RECEIVE_CITIES':
            return { ...state, cities: action.cities };
        case 'RECEIVE_COMPANIES':
            return { ...state, companies: action.companies };
        case 'RECEIVE_PACKED_SUCCESS':
            return { ...state, packedSucess: true };
        case 'DISMISS_RECEIVE_PACKED_SUCCESS':
            return { ...state, packedSucess: false };
        case 'RECEIVE_CLAIM_ORDER':
            if (state.currentSale == null) return state;
            let currentSale = { ...state.currentSale };
            currentSale.stateText = action.orders.stateText;
            currentSale.state = action.orders.saleState;
            action.orders.orderStatus.forEach((item: OrderStatus) => {
                let newSaleDetail = currentSale.meLiSaleDetails
                    .map((meliSaleDetail: MeLiSaleDetailContainer) => {
                        if (meliSaleDetail.orderNumber == item.orderId) {
                            if (item.claimStatus != "None") {
                                meliSaleDetail.claimStatus = item.claimStatus;
                                if (item.claimStatus == "OrderCancelled") {
                                    //meliSaleDetail.isDeleted = true;
                                }
                            }
                            else {
                                meliSaleDetail.claimStatus = "";
                            }
                        }
                        return meliSaleDetail;
                    });
                currentSale.meLiSaleDetails = newSaleDetail;
            });
            return { ...state, currentSale: currentSale };
        case 'RECEIVE_REOPEN_SUCCESS':
            return { ...state, confirmedReopen: true };
        case 'DISMISS_REOPEN_SUCCESS':
            return { ...state, confirmedReopen: false };
        case 'RECEIVE_MESSAGE_CONFIGURATIONS':
            return { ...state, messageConfigurations: action.messageConfigurations };
        case 'RECEIVE_MESSAGE_CONFIGURATION_UPDATE':
            return { ...state, messageConfigurationUpdateSuccess: true };
        case 'DISMISS_MESSAGE_CONFIGURATION_UPDATE':
            return { ...state, messageConfigurationUpdateSuccess: false };
        case 'RECEIVE_SHIPPED_SUCCESS':
            return { ...state, saleShippedSuccess: true };
        case 'DISMISS_SHIPPED_SUCCESS':
            return { ...state, saleShippedSuccess: false };
        case 'RECEIVE_AUDIT_LOGS':
            return { ...state, auditLogs: action.auditLogs };
        case 'RECEIVE_STOCK_CONFIRMED':
            return { ...state, amountOrderConfirmed: action.amountOrderConfirmed, messageStockConfirmedSuccess: true };
        case 'DISMISS_STOCK_CONFIRMED':
            return { ...state, messageStockConfirmedSuccess: false };
        case 'RECEIVE_INTERNAL_SALE_MESSAGES':
            if (state.currentSale != null) {
                let newSale = { ...state.currentSale };
                newSale.messages = action.messages;
                return { ...state, currentSale: newSale };
            }
            return state;
        case 'ORDER_NOTIFICATION_TOGGLE':
            let newList = state.orders.map((value: MeLiOrder) => {
                if (value.orderNumber == action.orderId) {
                    value.hasNotificationDisabled = !value.hasNotificationDisabled;
                }
                return value;
            });
            return { ...state, orders: newList };
        case 'RECEIVE_DELETE_SUCCESS':
            return { ...state, saleDeletedSuccess: true };
        case 'RECEIVE_DELETE_DISMISS':
            return { ...state, saleDeletedSuccess: false };
        case 'SHOW_CUSTOM_MESSAGE':
            return { ...state, customMessage: action.text };
        case 'DISMISS_CUSTOM_MESSAGE':
            return { ...state, customMessage: '' };
        case 'SHOW_CUSTOM_MESSAGE_POPUP':
            return { ...state, customMessagePopUp: action.text };
        case 'DISMISS_CUSTOM_MESSAGE_POPUP':
            return { ...state, customMessagePopUp: '' };
        case 'SAVE_MASS_SHIPMENT':
            return { ...state, packedSucess: true };
        case 'SALES_PRINTED':
            let sales = state.sales.map((sale: Sales) => {
                if (action.sales.find((id: number) => id == sale.id)) {
                    sale.isPrinted = true;
                    
                }
                return sale;
            })

            let currentSale2 = state.currentSale;
            if (currentSale2 != null) {
                let sale = currentSale2;
                if (action.sales.find((id: number) => id == sale.id)) {
                    currentSale2 = { ...currentSale2 };
                    currentSale2.isPrinted = true;
                }
            }
            return { ...state, sales: sales, currentSale: currentSale2 };
        case 'SALES_PRINTED_CHK':
            let salesChk = state.sales.map((sale: Sales) => {
                if (action.sales.find((id: number) => id == sale.id)) {
                    sale.isPrinted = true;

                }
                return sale;
            })

            let currentSale3 = state.currentSale;
            if (currentSale3 != null) {
                let sale = currentSale3;
                if (action.sales.find((id: number) => id == sale.id)) {
                    currentSale3 = { ...currentSale3 };
                    currentSale3.isPrinted = !currentSale3.isPrinted;
                }
            }
            return { ...state, sales: salesChk, currentSale: currentSale3 };

        case 'SALES_TAG_PRINTED':
            return { ...state};
        case 'PRINT_PDF':
            return { ...state, salesToPrint: action.Sales, customMessage: action.urlBaseQR }
        case 'RECEIVE_FULLJAUS_ORDER':
            return { ...state, orders: action.orders, emptyResults: action.orders.length == 0 };
        case 'RECEIVE_CONFIRMED_SALES_WITHOUT_MESSAGE':
            return { ...state, receiveSalesSuccessfully: true, countConfirmedSalesWithOutMessage: action.countConfirmedSalesWithOutMessage };
        case 'DISMISS_CONFIRMED_SALES_WITHOUT_MESSAGE':
            return { ...state, receiveSalesSuccessfully: false, countConfirmedSalesWithOutMessage: 0 };
        case 'RECEIVE_LOGISTICPRICES':
            return { ...state, logisticPrices: action.logisticPrices };
        case 'SALES_TAG_MASSIVE_PRINTED':
            return { ...state, printTagsMassiveSuccess: true };
        case 'DISMISS_TAG_MASSIVE_PRINTED':
            return { ...state, printTagsMassiveSuccess: false };
        default:
            return state;
    }
};
