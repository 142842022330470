import * as React from 'react';
import { Link } from 'react-router-dom';
import { Form, Card, Row, Col, FormControlProps, Toast } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as commonActionCreators from '../../store/Common/actions';
import * as salesActionCreators from '../../store/Sales/actions';
import { ApplicationState } from '../../store';
import { Button, Label } from 'reactstrap';
import { ReportsFilter } from '../../store/Common/types';
import { RouteComponentProps } from 'react-router';
import DatePicker from "react-datepicker";
import { SalesState, Sales, PageSaleState, MeLiSaleDetailContainer, SaleProduct, SaleMessage, Packer, Logistic } from '../../store/Sales/types';
import { bindActionCreators } from 'redux';
import { ChangeEvent } from 'react';
import { AppToaster } from "../shared/toaster";
import { Suggest, ItemRenderer } from "@blueprintjs/select";
import { Position, Tooltip, MenuItem, Popover, Dialog, Classes } from '@blueprintjs/core';
import CommonSale from './commonSale';
import { debug } from 'console';
import { UserState } from '../../store/Common/types';

declare var require: any;

type ParametersProps =
	UserState
	& SalesState
	& typeof commonActionCreators.actionCreators
	& typeof salesActionCreators.actionCreators
	& RouteComponentProps<{ startDateIndex: string }>
	& { pageSaleState: PageSaleState };


class Parameters extends React.Component<ParametersProps> {
	private get90DaysAgo = () => {
		const date = new Date();
		date.setDate(date.getDate() - 90);
		return date;
	}

	state: {
		validDate: Date,
		Cost1: number,
		CostHist1: number,
		Cost2: number,
		CostHist2: number,
		Cost3: number,
		CostHist3: number,
		pageSaleState: PageSaleState
	} = {
			validDate: new Date(),
			Cost1: 0,
			CostHist1: 0,
			Cost2: 0,
			CostHist2: 0,
			Cost3: 0,
			CostHist3: 0,
			pageSaleState: PageSaleState.All
		};

	componentDidMount() {
		//BACK-293
		if (this.props.logisticPrices.length <= 0) {
			this.props.getLogisticPrices();
		}

	}

	componentWillReceiveProps(nextProps: ParametersProps) {
		if (nextProps.customMessage.length > 0) {
			AppToaster.show({ intent: "success", message: nextProps.customMessage });

			this.props.dismissCustomMessage();
			this.search();
		}

		

	}

	private onFormSubmit = (evt: any) => {
		evt.preventDefault();
		this.search();
	}

	private search() {
		/*this.setState({ selectedSales: [], currentPage: 1 });*/

		this.props.getLogisticPrices();
	}

	private onDateChange = (input: string, date: Date) => {
		this.setState({ [input]: date })
	}

	getInt(valueString?: string) {
		if (valueString != undefined && valueString != null) {

			let value = valueString == "" ? 0 : parseInt(valueString);

			if (isNaN(value) || value < 0) {
				value = 0;
			}
			return value;
		}
		return 0;
	}

	private getIconTitle() {
		return <><i className="mdi mdi-server-network icon-title mr-2"></i>Configuración de Parámetros</>;
	}

	private renderHeader = () => {
		return <Card>
			<Card.Body>
				<h4 className="card-title">
					{this.getIconTitle()}
				</h4>
				
				<Form onSubmit={this.onFormSubmit}>

					<Row>
						<Col md={3} className="form-group">
							<Row>
								<Col md={6} >
									<label className="col-form-label">Válido desde</label>
								</Col>
								<Col md={6}>
									<DatePicker className="form-control w-100"
										selected={this.state.validDate}
										onChange={(date: Date) => { this.onDateChange('validDate', date) }}
										dateFormat="dd/MM/yyyy"
										minDate={this.get90DaysAgo()}
										//maxDate={new Date()}
									/>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row>
						<Col className="form-group" md={2}>
							<Label>
								Cordón 1
                            </Label>
						</Col>

						<Col className="form-group" md={2}>
							<Label>
								Precio
                            </Label>
							<Form.Control type="number"
								className="form-control" placeholder="Precio"
								id="txtCost1"
								value={((this.state.Cost1 != undefined && this.state.Cost1 > 0) || (this.props.logisticPrices != null && this.props.logisticPrices.length <= 1))  ? this.state.Cost1.toString() : this.props.logisticPrices[0].cost.toString() }
								onChange={(evt: React.ChangeEvent<FormControlProps>) => {
									this.setState({ Cost1: this.getFloat(evt.currentTarget.value) });
								}} />
						</Col>

						<Col className="form-group" md={2}>
							<Label>
								Precio Anterior
                            </Label>
							<Form.Control type="number" disabled={true}
								className="form-control" placeholder="Precio"
								value={(this.props.logisticPrices != null && this.state.CostHist1 != undefined && this.props.logisticPrices.length <= 0) ? this.state.CostHist1.toString() : this.props.logisticPrices[0].costHist.toString() }
								/>
						</Col>
						
					</Row>
					<Row>
						<Col className="form-group" md={2}>
							<Label>
								Cordón 2
                            </Label>
						</Col>

						<Col className="form-group" md={2}>
							<Label>
								Precio
                            </Label>
							<Form.Control type="number"
								className="form-control" placeholder="Precio"
								value={(this.state.Cost2 > 0 || this.props.logisticPrices.length <= 1) ? this.state.Cost2.toString() : this.props.logisticPrices[1].cost.toString() }
								onChange={(evt: React.ChangeEvent<FormControlProps>) => {
									this.setState({ Cost2: this.getFloat(evt.currentTarget.value) });
								}} />
						</Col>

						<Col className="form-group" md={2}>
							<Label>
								Precio Anterior
                            </Label>
							<Form.Control type="number" disabled={true}
								className="form-control" placeholder="Precio"
								value={this.props.logisticPrices.length <= 1 ? this.state.CostHist2.toString() : this.props.logisticPrices[1].costHist.toString() }
								/>
						</Col>

					</Row>
					<Row>
						<Col className="form-group" md={2}>
							<Label>
								Cordón 3
                            </Label>
						</Col>

						<Col className="form-group" md={2}>
							<Label>
								Precio
                            </Label>
							<Form.Control type="number"
								className="form-control" placeholder="Precio"
								value={((this.state.Cost3 != undefined && this.state.Cost3 > 0) || (this.props.logisticPrices != null && this.props.logisticPrices.length <= 1)) ? this.state.Cost3.toString() : this.props.logisticPrices[2].cost.toString() }
								onChange={(evt: React.ChangeEvent<FormControlProps>) => {
									this.setState({ Cost3: this.getFloat(evt.currentTarget.value) });
								}} />
						</Col>

						<Col className="form-group" md={2}>
							<Label>
								Precio Anterior
                            </Label>
							<Form.Control type="number" disabled={true}
								className="form-control" placeholder="Precio"
								value={this.props.logisticPrices.length <= 2 ? this.state.CostHist3.toString() : this.props.logisticPrices[2].costHist.toString() }
								 />
						</Col>

					</Row>
					<br />
					<br />
					<Row>
						<Col md={12}>

							<Button type="button"
								className="btn btn-success mr-2"
								onClick={(evt: any) => this.props.parametersSave(
									this.state.validDate, this.state.Cost1, this.state.Cost2, this.state.Cost3
								)} >
								Guardar</Button>

						</Col>
					</Row>
				</Form>
			</Card.Body>
		</Card>
	}

	private getStringDate(fecha: Date) {
		let options: Intl.DateTimeFormatOptions = {
			weekday: "long", day: "numeric", month: "numeric"
		};

		var fechaDate = new Date(fecha);
		var fechaStr = fechaDate.toLocaleDateString("es-ES", options).replace(",", "");;
		return this.capitalizeFirstLetter(fechaStr);
	}

	getFloat(valueString?: string) {
		if (valueString != undefined && valueString != null) {

			let value = valueString == "" ? 0 : parseFloat(valueString);

			if (isNaN(value) || value < 0) {
				value = 0;
			}
			return value;
		}
		return 0;
	}

	private capitalizeFirstLetter(valStr: String) {
		return valStr.charAt(0).toUpperCase() + valStr.slice(1);
	}
	

	render() {
		return (
			<>
				<div className="grid-margin">
					{this.renderHeader()}
				</div>
			</>
		)
	}
}

export default connect(
	(state: ApplicationState) => { return { ...state.userState, ...state.salesState } },
	(dispatch: any) => bindActionCreators({ ...commonActionCreators.actionCreators, ...salesActionCreators.actionCreators }, dispatch)
)(Parameters as any);

