import * as React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import { Dropdown } from 'react-bootstrap';
import { UserState } from '../../store/Common/types';
import { actionCreators } from '../../store/Common/actions';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';

declare var require: any;

// At runtime, Redux will merge together...
type SidebarProps =
    UserState // ... state we've requested from the Redux store
    & typeof actionCreators;


class Sidebar extends React.Component<SidebarProps> {
    state: {
        userPagesMenuOpen: boolean,
        salesUiMenuOpen: boolean,
        currentUrl: string
    } = {
            userPagesMenuOpen: false,
            salesUiMenuOpen: false,
            currentUrl: ""
        };

    toggleMenuState(menuState: string) {
        const rawState = this.state as any as Record<string, boolean>;
        if (rawState[menuState]) {
            this.setState({ [menuState]: false });
        } else if (Object.keys(this.state).length === 0) {
            this.setState({ [menuState]: true });
        } else {
            Object.keys(this.state).forEach(i => {
                this.setState({ [i]: false });
            });
            this.setState({ [menuState]: true });
        }
    }

    componentDidUpdate(prevProps: SidebarProps) {
        if (this.state.currentUrl !== window.location.href) {
            this.setState({ currentUrl: window.location.href });
        }
    }

    onRouteChanged() {
        if (document == null) {
            return;
        }
        let sideBar = document.querySelector('#sidebar');
        if (sideBar == null
            || sideBar.classList == null) {
            return;
        }

        sideBar.classList.remove('active');
        Object.keys(this.state).forEach(i => {
            if (i != 'salesUiMenuOpen') {
                this.setState({ [i]: false });
            }
        });

        const dropdownPaths = [
            { path: '/basic-ui', state: 'salesUiMenuOpen' },
            { path: '/form-elements', state: 'formElementsMenuOpen' },
            { path: '/tables', state: 'tablesMenuOpen' },
            { path: '/icons', state: 'iconsMenuOpen' },
            { path: '/charts', state: 'chartsMenuOpen' },
            { path: '/user-pages', state: 'userPagesMenuOpen' },
        ];

        dropdownPaths.forEach((obj => {
            if (this.isPathActive(obj.path)) {
                this.setState({ [obj.state]: true })
            }
        }));

    }
    render() {
        
        return (
            <nav className="sidebar sidebar-offcanvas" id="sidebar">
                <div className="text-center sidebar-brand-wrapper d-flex align-items-center">
                    <Link className="sidebar-brand brand-logo"
                        to="/home"
                        onClick={() => this.onRouteChanged()}>
                        <img src={require("../../assets/images/logo.png")} alt="logo" />
                    </Link>
                    <a className="sidebar-brand brand-logo-mini pt-3" href="/home"><img src={require("../../assets/images/logo_mini.png")} alt="logo" /></a>
                </div>
                <ul className="nav">
                    <li className="nav-item nav-profile not-navigation-link">
                        <div className="nav-link">
                            <Dropdown>
                                <Dropdown.Toggle id="1" className="nav-link user-switch-dropdown-toggler p-0 toggle-arrow-hide bg-transparent border-0 w-100">
                                    <div className="d-flex justify-content-between align-items-start">
                                        <div className="profile-image">
                                            <i className="fa fa-user-circle-o"></i>
                                        </div>
                                        <div className="text-left ml-3">
                                            <p className="profile-name">{this.props.user.name}</p>
                                            <small className="designation text-muted text-small">{this.props.user.roleName}</small>
                                            <span className="status-indicator online"></span>
                                        </div>
                                    </div>
                                </Dropdown.Toggle>
                            </Dropdown>
                        </div>
                    </li>
                </ul>
                <strong className="ml-3 menu-header">Ventas</strong>
                <ul className="nav">
                    <li className={this.isPathActive('/buscarVentas') || this.isPathActive('/home') ? 'nav-item active' : 'nav-item'}>
                        <Link className={this.isPathActive('/buscarVentas') || this.isPathActive('/home') ? 'nav-link active' : 'nav-link'}
                            to="/buscarVentas"
                            onClick={() => this.onRouteChanged()}>
                            <i className="mdi mdi-magnify menu-icon"></i>
                            <span className="menu-title">Búsqueda por estado</span>
                        </Link>
                    </li>
                    {this.props.user.role == "Admin" || this.props.user.role == "Seller" || this.props.user.role == "SellerLead" ?
                        <li className={this.isPathActive('/tiemposElaboracion') ? 'nav-item active' : 'nav-item'}>
                            <Link className={this.isPathActive('/tiemposElaboracion') ? 'nav-link active' : 'nav-link'}
                                to="/tiemposElaboracion"
                                onClick={() => this.onRouteChanged()}>
                                <i className="mdi mdi-factory menu-icon"></i>
                                <span className="menu-title">Ordenes en Elaboración</span>
                            </Link>
                        </li>
                        : <></>}
                    {
                        this.props.user.role == "Admin" || this.props.user.role == "Seller" || this.props.user.role == "SellerLead" ? <>
                            <li className={this.isPathActive('/ventas/MercadoLibre') ? 'nav-item active' : 'nav-item'}>
                                <div className={this.state.salesUiMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('salesUiMenuOpen')} data-toggle="collapse">
                                    <i className="fa fa-dollar menu-icon"></i>
                                    <span className="menu-title">Crear venta</span>
                                    <i className="menu-arrow"></i>
                                </div>
                                <Collapse in={this.state.salesUiMenuOpen}>
                                    <ul className="nav flex-column sub-menu">
                                        <li className="nav-item">
                                            <Link className={this.isPathActive('/ventas/MercadoLibre') ? 'nav-link active' : 'nav-link'} to="/ventas/MercadoLibre" onClick={() => this.onRouteChanged()}><i className="mdi mdi-animation mr-2"></i>Orden ML</Link>
                                        </li>
                                        <li className="nav-item"> <Link className={this.isPathActive('/ventas/Link') ? 'nav-link active' : 'nav-link'} to="/ventas/Link" onClick={() => this.onRouteChanged()}><i className="mdi mdi-link mr-2"></i>Link de pago</Link></li>
                                        <li className="nav-item"> <Link className={this.isPathActive('/ventas/Transferencia') ? 'nav-link active' : 'nav-link'} to="/ventas/Transferencia" onClick={() => this.onRouteChanged()}><i className="mdi mdi-bank mr-2"></i>Transferencia</Link></li>
                                        <li className="nav-item"> <Link className={this.isPathActive('/ventas/Cash') ? 'nav-link active' : 'nav-link'} to="/ventas/Cash" onClick={() => this.onRouteChanged()}><i className="mdi mdi-cash-usd mr-2"></i>Efectivo</Link></li>
                                        {/*<li className="nav-item">*/}
                                        {/*    <Link className={this.isPathActive('/ventas/Fulljaus') ? 'nav-link active' : 'nav-link'} to="/ventas/Fulljaus" onClick={() => this.onRouteChanged()}><i className="mdi mdi-animation mr-2"></i>Orden Fulljaus</Link>*/}
                                        {/*</li>*/}
                                    </ul>
                                </Collapse>
                            </li>
                            <li className={this.isPathActive('/ventas') ? 'nav-item active' : 'nav-item'}>
                                <Link className={this.isPathActive('/ventas') ? 'nav-link active' : 'nav-link'}
                                    to="/ventas"
                                    onClick={() => this.onRouteChanged()}>
                                    <i className="mdi mdi-briefcase-check menu-icon"></i>
                                    <span className="menu-title">Revisar Ventas</span>
                                </Link>
                            </li> </> : <></>
                    }
                </ul>
                {this.props.user.role == "Admin" || this.props.user.role == "Administrative" ?
                    <>
                        <strong className="ml-3 menu-header">Administración</strong>
                        <ul className="nav">
                            <li className={this.isPathActive('/ventasEnAdministracion') ? 'nav-item active' : 'nav-item'}>
                                <Link className={this.isPathActive('/ventasEnAdministracion') ? 'nav-link active' : 'nav-link'}
                                    to="/ventasEnAdministracion"
                                    onClick={() => this.onRouteChanged()}>
                                    <i className="mdi mdi-cash-multiple menu-icon"></i>
                                    <span className="menu-title">Ventas en Administración</span>
                                </Link>
                            </li>
                            <li className={this.isPathActive('/parametros') ? 'nav-item active' : 'nav-item'}>
                                <Link className={this.isPathActive('/parametros') ? 'nav-link active' : 'nav-link'}
                                    to="/parametros"
                                    onClick={() => this.onRouteChanged()}>
                                    <i className="mdi mdi-server-network menu-icon"></i>
                                    <span className="menu-title">Parametros</span>
                                </Link>
                            </li>


                            {this.props.user.role == "Administrative" ?
                                <><li className={this.isPathActive('/reporteFLEX') ? 'nav-item active' : 'nav-item'}>
                                    <Link className={this.isPathActive('/reporteFLEX') ? 'nav-link active' : 'nav-link'}
                                        to="/reporteFLEX"
                                        onClick={() => this.onRouteChanged()}>
                                        <i className="mdi mdi-file-import menu-icon"></i>
                                        <span className="menu-title">Reportes</span>
                                    </Link>
                                </li>
                                
                                </>
                                : <></>
                            }
                        </ul>
                    </>
                    : <></>
                }
                {this.props.user.role == "Admin" || this.props.user.role == "Packer" ? <>
                    <strong className="ml-3 menu-header">Depósito</strong>
                    <ul className="nav">
                            <li className={this.isPathActive('/ventasListasParaEmpaquetar') ? 'nav-item active' : 'nav-item'}>
                                <Link className={this.isPathActive('/ventasListasParaEmpaquetar') ? 'nav-link active' : 'nav-link'}
                                    to="/ventasListasParaEmpaquetar"
                                    onClick={() => this.onRouteChanged()}>
                                <i className="mdi mdi-printer menu-icon"></i>
                                    <span className="menu-title">Resumenes y etiquetas</span>
                                </Link>
                            </li>
                            {/*//BACK 157*/}
                            <li className={this.isPathActive('/ventasListasParaEmpaquetarACNoMeli') ? 'nav-item active' : 'nav-item'}>
                            <Link className={this.isPathActive('/ventasListasParaEmpaquetarACNoMeli') ? 'nav-link active' : 'nav-link'}
                                to="/ventasListasParaEmpaquetarACNoMeli"
                                    onClick={() => this.onRouteChanged()}>
                                <i className="mdi mdi-truck-fast menu-icon"></i>
                                    <span className="menu-title">Expresos y motos</span>
                                </Link>
                            </li>

                            <li className={this.isPathActive('/registrarEnviosMasivos') ? 'nav-item active' : 'nav-item'}>
                                <Link className={this.isPathActive('/registrarEnviosMasivos') ? 'nav-link active' : 'nav-link'}
                                    to="/registrarEnviosMasivos"
                                    onClick={() => this.onRouteChanged()}>
                                <i className="mdi mdi-package-variant menu-icon"></i>
                                    <span className="menu-title">Información de empaque</span>
                                </Link>
                            </li>
                            <li className={this.isPathActive('/ventasEmpaquetadas') ? 'nav-item active' : 'nav-item'}>
                                <Link className={this.isPathActive('/ventasEmpaquetadas') ? 'nav-link active' : 'nav-link'}
                                    to="/ventasEmpaquetadas"
                                    onClick={() => this.onRouteChanged()}>
                                    <i className="mdi mdi-package-variant-closed menu-icon"></i>
                                    <span className="menu-title">Despachar</span>
                                </Link>
                            </li>
                    </ul>
                </>
                    : <></>
                }
                {this.props.user.role == "SellerLead" ?
                    <>
                        <strong className="ml-3 menu-header">Herramientas</strong>
                        <ul className="nav">
                            <li className={this.isPathActive('/configuracion') ? 'nav-item active' : 'nav-item'}>
                                <Link className={this.isPathActive('/configuracion') ? 'nav-link active' : 'nav-link'}
                                    to="/configuracion"
                                    onClick={() => this.onRouteChanged()}>
                                    <i className="mdi mdi-settings menu-icon"></i>
                                    <span className="menu-title">Configuración</span>
                                </Link>
                            </li>
                            <li className={this.isPathActive('/reporteFLEX') ? 'nav-item active' : 'nav-item'}>
                                <Link className={this.isPathActive('/reporteFLEX') ? 'nav-link active' : 'nav-link'}
                                    to="/reporteFLEX"
                                    onClick={() => this.onRouteChanged()}>
                                    <i className="mdi mdi-file-import menu-icon"></i>
                                    <span className="menu-title">Reportes</span>
                                </Link>
                            </li>
                        </ul>
                    </>
                    : <></>
                }
                {this.props.user.role == "Admin" ?
                    <>
                        <strong className="ml-3 menu-header">Herramientas</strong>
                        <ul className="nav">
                            <li className={this.isPathActive('/configuracion') ? 'nav-item active' : 'nav-item'}>
                                <Link className={this.isPathActive('/configuracion') ? 'nav-link active' : 'nav-link'}
                                    to="/configuracion"
                                    onClick={() => this.onRouteChanged()}>
                                    <i className="mdi mdi-settings menu-icon"></i>
                                    <span className="menu-title">Configuración</span>
                                </Link>
                            </li>

                            
                            <li className={this.isPathActive('/administrarUsuarios') ? 'nav-item active' : 'nav-item'}>
                                <Link className={this.isPathActive('/administrarUsuarios') ? 'nav-link active' : 'nav-link'}
                                    to="/administrarUsuarios"
                                    onClick={() => this.onRouteChanged()}>
                                    <i className="mdi mdi-account-settings menu-icon"></i>
                                    <span className="menu-title">Administrar Usuarios</span>
                                </Link>
                            </li>
                            <li className={this.isPathActive('/reporteFLEX') ? 'nav-item active' : 'nav-item'}>
                                <Link className={this.isPathActive('/reporteFLEX') ? 'nav-link active' : 'nav-link'}
                                    to="/reporteFLEX"
                                    onClick={() => this.onRouteChanged()}>
                                    <i className="mdi mdi-file-import menu-icon"></i>
                                    <span className="menu-title">Reportes</span>
                                </Link>
                            </li>
                            <li className={this.isPathActive('/servicios') ? 'nav-item active' : 'nav-item'}>
                                <Link className={this.isPathActive('/servicios') ? 'nav-link active' : 'nav-link'}
                                    to="/servicios"
                                    onClick={() => this.onRouteChanged()}>
                                    <i className="mdi mdi-apps menu-icon"></i>
                                    <span className="menu-title">Servicios</span>
                                </Link>
                            </li>
                        </ul>
                    </>
                    : <></>
                }

            </nav>
        );
    }

    isPathActive(path: string) {
        if (this.state.currentUrl) {
            return this.state.currentUrl.endsWith(path);
        }
        return false;
    }

    componentDidMount() {
        this.onRouteChanged();
        // add className 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
        const body = document.querySelector('body');
        if (body == null) {
            return;
        }

        document.querySelectorAll('.sidebar .nav-item').forEach((el) => {

            el.addEventListener('mouseover', function () {
                if (body.classList.contains('sidebar-icon-only')) {
                    el.classList.add('hover-open');
                }
            });
            el.addEventListener('mouseout', function () {
                if (body.classList.contains('sidebar-icon-only')) {
                    el.classList.remove('hover-open');
                }
            });
        });
    }

}

export default connect(
    (state: ApplicationState) => state.userState, // Selects which state properties are merged into the component's props
    actionCreators // Selects which action creators are merged into the component's props
)(Sidebar as any);