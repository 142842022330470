import * as React from 'react';
import { Link } from 'react-router-dom';
import { Form, Card, Row, Col, FormControl, FormControlProps, Toast } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as commonActionCreators from '../../store/Common/actions';
import * as salesActionCreators from '../../store/Sales/actions';
import { ApplicationState } from '../../store';
import { Button, Label, Input } from 'reactstrap';
import { ReviewFilter, UserState } from '../../store/Common/types';
import { RouteComponentProps } from 'react-router';
import DatePicker from "react-datepicker";
import { SalesState, MeLiOrder, SaleProduct, PageSaleState } from '../../store/Sales/types';
import { bindActionCreators } from 'redux';
import { ReactEventHandler, FormEventHandler, ChangeEventHandler, ChangeEvent } from 'react';
import { Position, Toaster, MenuItem, Tooltip, Dialog, Classes } from "@blueprintjs/core";
import { AppToaster } from "../shared/toaster"
import { Suggest, ItemRenderer } from "@blueprintjs/select";
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';


type MLSaleProps =
    UserState
    & SalesState
    & typeof commonActionCreators.actionCreators
    & typeof salesActionCreators.actionCreators
    & RouteComponentProps<{ idSale?: string }>;


class MLSale extends React.Component<MLSaleProps> {
    private get90DaysAgo = () => {
        const date = new Date();
        date.setDate(date.getDate() - 90);
        return date;
    }

    private getTwoWeeksAgo = () => {
        const date = new Date();
        date.setDate(date.getDate() - 15);
        return date;
    }

    private itemsPerPage = () => this.props.user.itemsPerPage;

    state: {
        fromDate: Date,
        toDate: Date,
        userName: string,
        deliveryType: string,
        hasCard: boolean | undefined,
        seletedOrders: MeLiOrder[],
        currentPage: number,
        canEditUserName: boolean,
        orderNumber: number,
        showPopUp: boolean,
        messagePopup: string,
        showConfirmationSearchModal: boolean,
    } = {
            fromDate: this.getTwoWeeksAgo(),
            toDate: new Date(),
            userName: "",
            deliveryType: "",
            hasCard: undefined,
            seletedOrders: [],
            currentPage: 1,
            canEditUserName: true,
            orderNumber: 0,
            showPopUp: false,
            messagePopup: "",
            showConfirmationSearchModal: false
        };

    private isInSale = () => this.props.match.params.idSale != undefined;

    componentDidMount() {
        if (this.props.match.params.idSale != undefined) {
            let currentId = parseInt(this.props.match.params.idSale);
            if (this.props.currentSale == null || this.props.currentSale.id != currentId) {
                this.props.getSale(currentId);
            }
        }

        if (this.props.match.params.idSale != undefined && this.props.currentSale != null
            && this.props.currentSale.meLiSaleDetails.length > 0) {
            this.setState({ userName: this.props.currentSale.meLiSaleDetails[0].userName, canEditUserName: false });
        }
        else {
            //this.setState({ userName: '', canEditUserName: true });
            this.props.cleanOrders();

            let filters = this.props.filters[PageSaleState.MLSale] as ReviewFilter;

            if (filters != undefined) {
                this.setState({
                    fromDate: filters.fromDate,
                    toDate: filters.toDate,
                    userName: filters.userName,
                    deliveryType: filters.deliveryType,
                    hasCard: filters.ordersWithCard,
                    orderNumber: filters.orderNumber
                }, () => {

                    //if (this.props.sales.length > 0) {
                    this.search();
                    //}
                });
            }
            //else {
            //    if (this.props.sales.length > 0) {
            //        this.search();
            //    }
            //}
        }
    }

    getInt(valueString?: string) {
        if (valueString != undefined && valueString != null) {

            let value = valueString == "" ? 0 : parseInt(valueString);

            if (isNaN(value) || value < 0) {
                value = 0;
            }
            return value;
        }
        return 0;
    }

    handleCancelClick() {
        if (this.isInSale() && this.props.currentSale != null) {
            this.props.history.push(`/ventas/detalle/${this.props.currentSale.id}`);
        }
    }

    componentWillReceiveProps(nextProps: MLSaleProps) {
        if (nextProps.salesCreatedSuccessfully) {
            var msgDuplicateSale = '';
            if (nextProps.DuplicateSales > 0) {
                msgDuplicateSale = nextProps.DuplicateSales + ' estaban duplicadas.';
            }
            if (nextProps.amountSalesCreated == 0 && this.isInSale() && nextProps.DuplicateSales == 0) {
                AppToaster.show({ intent: "success", message: "Venta actualizada success" });
                if (this.props.currentSale != null) {
                    this.props.history.push(`/ventas/detalle/${this.props.currentSale.id}`);
                }
            }
            else if (nextProps.amountSalesCreated == 0 && nextProps.DuplicateSales > 0) {
                AppToaster.show({ intent: "success", message: "No se han crado ventas. " + msgDuplicateSale });
                this.search(false);
            }
            else if (nextProps.amountSalesCreated == 1 && nextProps.ContainsMessages == 1 && nextProps.DuplicateSales == 0) {
                AppToaster.show({ intent: "success", message: "Se ha creado 1 venta exitosamente. " + msgDuplicateSale });
                this.props.history.push(`/ventas/detalle/${nextProps.lastSaleCreated}`);
            }
            else if (nextProps.amountSalesCreated == 1 && nextProps.ContainsMessages == 0) {
                AppToaster.show({ intent: "success", message: "Se ha creado 1 venta y se ha enviado directo a Administración. " + msgDuplicateSale });
                this.search(false);
            }
            else if (nextProps.ContainsMessages > 0) {
                if (nextProps.ContainsMessages == 1) {
                    AppToaster.show({
                        intent: "success", message: ReactHtmlParser(`Se han creado ${nextProps.amountSalesCreated} ventas. <br /> ${nextProps.ContainsMessages} se ha enviado directo a Administración. ` + msgDuplicateSale)
                    });
                }
                else {
                    AppToaster.show({ intent: "success", message: ReactHtmlParser(`Se han creado ${nextProps.amountSalesCreated} ventas. <br /> ${nextProps.ContainsMessages} se han enviado directo a Administración. ` + msgDuplicateSale) });
                }
                this.search(false);
            }
            else {
                AppToaster.show({ intent: "success", message: `Se han creado ${nextProps.amountSalesCreated} ventas exitosamente. ` + msgDuplicateSale });
                this.search(false);
            }

            this.props.dismissSuccessfullyCreated();
        }

        if (nextProps.customMessage.length > 0) {
            AppToaster.show({ intent: "warning", message: nextProps.customMessage.split("\n").map((item: string) => <> {item}<br /></>) });

            this.props.dismissCustomMessage();
            this.search(false);
        }

        if (nextProps.customMessagePopUp.length > 0) {
            this.setState({
                showPopUp: true,
                messagePopup: nextProps.customMessagePopUp
            });
            this.props.dismissCustomMessagePopUp();
            this.search(false);
        }

        console.log(nextProps.match.params.idSale, this.props.match.params.idSale, nextProps.currentSale);
        if (nextProps.match.params.idSale != undefined && nextProps.currentSale != null
            && nextProps.currentSale.meLiSaleDetails.length > 0) {
            this.setState({ userName: nextProps.currentSale.meLiSaleDetails[0].userName, canEditUserName: false });
        }
        else if (
            this.props.match.params.idSale != undefined &&
            (nextProps.match.params.idSale != this.props.match.params.idSale
                || (this.props.currentSale != null &&
                    nextProps.match.params.idSale != this.props.currentSale.id.toString()))) {
            this.setState({ userName: '', canEditUserName: true });
        }
    }

    componentWillUnmount() {
        this.setState({ userName: '', canEditUserName: true });
    }


    private onFormSubmit = (evt: any) => {
        evt.preventDefault();
        this.search();
    }

    private search(showMessage: boolean = true) {
        if (showMessage) {
            AppToaster.show({ intent: "success", message: "Se buscarán nuevas órdenes en Mercadolibre. Este proceso puede tardar unos segundos" });
        }
        this.setState({ seletedOrders: [], currentPage: 1 });
        this.props.getOrders(this.state.fromDate,
            this.state.toDate,
            this.state.userName,
            this.state.deliveryType,
            this.state.hasCard,
            this.state.orderNumber);
    }

    private showDialogCacheSearch = () => {
        this.setState({ showConfirmationSearchModal: true });
    }

    private confirmationSearchDialog = () => {
        return <Dialog
            icon="info-sign"
            onClose={this.handleCloseSearchConfirmation}
            title="Confirmación"
            isOpen={this.state.showConfirmationSearchModal}
        >
            <div className={Classes.DIALOG_BODY}>
                <p>Si confirma, el sistema intentara buscara todas las ordenes de ML pendientes. ¿Desea Continuar?</p>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button onClick={this.handleCloseSearchConfirmation}>Cancelar</Button>
                    <Button color="primary" className="ml-2" onClick={() => {
                        this.searchClearCache(true);
                    }}>Confirmar</Button>
                </div>
            </div>
        </Dialog>
    }

    private handleCloseSearchConfirmation = () => {
        this.setState({ showConfirmationSearchModal: false});
    }

    private searchClearCache(showMessage: boolean = true) {
        if (showMessage) {
            AppToaster.show({ intent: "success", message: "Se buscarán nuevas órdenes en Mercadolibre. Este proceso puede tardar unos segundos" });
        }
        this.setState({ seletedOrders: [], currentPage: 1 });
        this.props.getOrders(this.state.fromDate,
            this.state.toDate,
            this.state.userName,
            this.state.deliveryType,
            this.state.hasCard,
            this.state.orderNumber, false,
            true);

        this.handleCloseSearchConfirmation();
    }
    

    private onDateChange = (input: string, date: Date) => {
        this.setState({ [input]: date })
    }

    private orderSelectedFromSameUsers = () => {
        if (this.state.seletedOrders.length == 0) {
            return false;
        }

        var users: { [userName: string]: string[]; } = {};
        let hasMultipleCollect = false;

        this.state.seletedOrders.forEach((order: MeLiOrder) => {
            if (users[order.userName] == undefined) {
                users[order.userName] = [];
            }
            users[order.userName].push(order.deliveryType);
        });

        Object.keys(users).forEach((userName: string) => {
            let hasCollect = false;
            let hasFlex = false;
            users[userName].forEach((deliveryType: string) => {
                if (deliveryType == "0") {
                    if (hasCollect || hasFlex) {
                        hasMultipleCollect = true;
                    }
                    hasCollect = true;
                }
                if (deliveryType == "1") {
                    hasFlex = true;
                }
            })
        });

        return Object.keys(users).length <= 1
            && !hasMultipleCollect;
    }

    private pushSelectedOrder(order: MeLiOrder) {
        let selectedOrders = this.state.seletedOrders;
        if (selectedOrders.includes(order)) {
            selectedOrders = selectedOrders.filter((value: MeLiOrder) => order != value);
        }
        else {
            selectedOrders.push(order);
        }

        this.setState({ seletedOrders: selectedOrders });
    }

    private currentOrders = () => this.props.orders.slice(this.itemsPerPage() * (this.state.currentPage - 1), this.itemsPerPage() * this.state.currentPage);

    private togglerSelectedAllOrders() {
        if (this.state.seletedOrders.length == this.props.orders.length) {
            this.setState({ seletedOrders: [] });
        }
        else {
            this.setState({ seletedOrders: [...this.props.orders] });
        }
    }
    
    private escapeRegExpChars(text: string) {
        return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
    }
    
    private highlightText(text: string, query: string) {
        let lastIndex = 0;
        const words = query
            .split(/\s+/)
            .filter(word => word.length > 0)
            .map(this.escapeRegExpChars);
        if (words.length === 0) {
            return [text];
        }
        const regexp = new RegExp(words.join("|"), "gi");
        const tokens: React.ReactNode[] = [];
        while (true) {
            const match = regexp.exec(text);
            if (!match) {
                break;
            }
            const length = match[0].length;
            const before = text.slice(lastIndex, regexp.lastIndex - length);
            if (before.length > 0) {
                tokens.push(before);
            }
            lastIndex = regexp.lastIndex;
            tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
        }
        const rest = text.slice(lastIndex);
        if (rest.length > 0) {
            tokens.push(rest);
        }
        return tokens;
    }

    private renderUser: ItemRenderer<string> = (user, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        const text = user;
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={user}
                key={user}
                onClick={handleClick}
                text={this.highlightText(text, query)}
            />
        );
    };

    private handleRadioChange = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
        if (changeEvent.target.value == undefined || changeEvent.target.value == "") {
            this.setState({
                hasCard: undefined
            });
        }
        else {
            this.setState({
                hasCard: changeEvent.target.value == "true"
            });
        }
        
    }

    private handleClosePopup = () => {
        this.setState({ showPopUp: false, messagePopup: "" });
    }

    private popupDialog = () => {
        return <Dialog
            //icon="info-sign"
            onClose={this.handleClosePopup}
            //title="Confirmación"
            isOpen={this.state.showPopUp}
            className="naranja"
        >
            <div className={Classes.DIALOG_BODY}>
                <p>{ReactHtmlParser(this.state.messagePopup)}</p>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS} style={{ justifyContent: "center" }}>
                    <Button onClick={this.handleClosePopup} color="primary">Aceptar</Button>
                </div>
            </div>
        </Dialog>
    }

    private filterSelected() {

        var filterSelectedCount = 0;

        if (this.state.fromDate.toDateString() != this.getTwoWeeksAgo().toDateString())
            filterSelectedCount++;

        if (this.state.toDate.toDateString() != new Date().toDateString())
            filterSelectedCount++;

        if (this.state.userName != "")
            filterSelectedCount++;

        if (this.state.deliveryType != "")
            filterSelectedCount++;

        if (this.state.hasCard != undefined)
            filterSelectedCount++;         

        if (this.state.orderNumber != 0)
            filterSelectedCount++;

        return filterSelectedCount;
    }

    private cleanFilters() {

        this.setState({
            fromDate: this.getTwoWeeksAgo(),
            toDate: new Date(),
            userName: "",
            deliveryType: "",
            orderNumber: 0,
            hasCard: undefined
        }, () => {
            this.props.filters[PageSaleState.MLSale] = undefined;
            //if (this.props.sales.length > 0) {
                this.search();
            //}
        });
    }

    private renderHeader = () => {
        return <Card>
            <Card.Body>
                <h4 className="card-title">
                    {this.isInSale() ? "Agregar Orden a la venta" : <> <i className="mdi mdi-animation icon-title mr-2"></i>Crear una nueva venta a partir de una orden de Mercado Libre</>} 
            </h4>
                <p className="card-description"> Seleccione fechas, usuario y forma de entrega antes de buscar </p>
                <Form onSubmit={this.onFormSubmit}>
                    <Row>
                        <Col className="form-group" md={3}>
                            <Row>
                                <Col md={6}>
                                    <label className="col-form-label">Fecha desde</label>
                                </Col>
                                <Col md={6}>
                                    <DatePicker className="form-control w-100"
                                        selected={this.state.fromDate}
                                        onChange={(date: Date) => { this.onDateChange('fromDate', date) }}
                                        dateFormat="dd/MM/yyyy"
                                        minDate={this.get90DaysAgo()}
                                        maxDate={new Date()}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col className="form-group" md={3}>
                            <Row>
                                <Col md={6}>
                                    <label className="col-form-label">Fecha hasta</label>
                                </Col>
                                <Col md={6}>
                                    <DatePicker className="form-control w-100"
                                        selected={this.state.toDate}
                                        onChange={(date: Date) => { this.onDateChange('toDate', date) }}
                                        dateFormat="dd/MM/yyyy"
                                        minDate={this.get90DaysAgo()}
                                        maxDate={ new Date() }
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="form-group" md={6}>
                            <Label>
                                Usuario de ML
                            </Label>

                            <Form.Group className="d-flex search-field">
                                <Form.Control type="text" placeholder="Usuario" size="lg"
                                    disabled={ !this.state.canEditUserName }
                                    onChange={(e: any) => this.setState({ userName: e.target.value })}
                                    value={this.state.userName} />
                            </Form.Group>
                        </Col>
                        <Col className="form-group" md={6}>
                            <Label>
                                Forma de entrega
                            </Label>
                            <select
                                className="form-control"
                                onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => this.setState({ deliveryType: evt.currentTarget.value })}
                                value={ this.state.deliveryType }>
                                <option value="">Seleccione una opción...</option>
                                <option value="Collect">Colecta</option>
                                <option value="Flex">Flex</option>
                                <option value="ToBeAgreed">A Convenir</option>
                            </select>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="form-group" md={3}>
                            <Label>
                                # Órden
                            </Label>
                            <Form.Control type="number"
                                className="form-control"
                                id="orderNumber"
                                placeholder="# Órden"
                                onChange={(evt: React.ChangeEvent<FormControlProps>) => this.setState({ orderNumber: this.getInt(evt.currentTarget.value) })}
                                value={this.state.orderNumber > 0 ? this.state.orderNumber.toString() : ""}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="form-group" md={12}>
                            <div className="col-sm-4">
                                <div className="form-check">
                                    <label className="form-check-label">
                                        <input type="radio" className="form-check-input"
                                            name="hasCardRadio"
                                            id="hasCardRadio1"
                                            value={'false'}
                                            onChange={this.handleRadioChange}
                                            checked={this.state.hasCard != undefined && !this.state.hasCard} /> Solo órdenes sin carrito
                                            <i className="input-helper"></i>
                                    </label>
                                </div>
                            </div>
                            <div className="col-sm-5">
                                <div className="form-check">
                                    <label className="form-check-label">
                                        <input type="radio" className="form-check-input"
                                            name="hasCardRadio"
                                            id="hasCardRadio2"
                                            value={'true'}
                                            onChange={this.handleRadioChange}
                                            checked={this.state.hasCard != undefined && this.state.hasCard} /> Solo órdenes con carrito
                            <i className="input-helper"></i>
                                    </label>
                                </div>
                            </div>
                            <div className="col-sm-5">
                                <div className="form-check">
                                    <label className="form-check-label">
                                        <input type="radio" className="form-check-input"
                                            name="hasCardRadio"
                                            id="hasCardRadio3"
                                            value={undefined}
                                            onChange={this.handleRadioChange}
                                            checked={this.state.hasCard == undefined} /> Indistinto
                            <i className="input-helper"></i>
                                    </label>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2}>
                            <button type="submit" className="btn btn-primary mr-2">Buscar</button>
                        </Col>
                        <Col md={10} className="float-right">
                            <Button className="float-right" color="secondary" onClick={() => { this.showDialogCacheSearch(); }}>Buscar actualizando cache</Button>
                        </Col>
                    </Row>
                </Form>
            </Card.Body>
        </Card>
    }
    
    private getTotal = (order: MeLiOrder) => {
        let total = 0;
        order.details.forEach((item: SaleProduct) => { total += (item.price * item.quantity); })
        return total;
    }

    private convertDeliveryType = (deliveryType: string) => {
        if (deliveryType == "0") {
            return "Colecta";
        }
        else if (deliveryType == "1") {
            return "Flex";
        }
        else {
            return "A Convenir";
        }
    }

    private renderBody = () => {
        const orders = this.props.orders;
        return orders.length == 0 ?
            this.props.emptyResults ? <Card>
                <Card.Body>
                    <h4 className="card-title">
                        No se han encontrado resultados para su búsqueda
                    </h4>
                    {this.filterSelected() > 0 ?
                        <>
                            <Link className='nav-link' to="#">
                                <i className="mdi mdi-information"></i>
                                <span className="menu-title"> Atención: Posee {this.filterSelected()} filtros seleccionados. </span>
                                <Button color="success" className="ml-2 mr-2"
                                    onClick={(evt: any) => this.cleanFilters()}>
                                    Limpiar Filtros
							</Button>
                            </Link>
                        </>
                        :
                        <></>
                    }
                    </Card.Body>
                </Card>
                :
                    <></>
            :
            <Card>
                <Card.Body>
                    {this.filterSelected() > 0 ?
                        <>
                            <Link className='nav-link' to="#">
                                <i className="mdi mdi-information"></i>
                                <span className="menu-title"> Atención: Posee {this.filterSelected()} filtros seleccionados. </span>
                                <Button color="success" className="ml-2 mr-2"
                                    onClick={(evt: any) => this.cleanFilters()}>
                                    Limpiar Filtros
							</Button>
                            </Link>
                        </>
                        :
                        <></>
                    }
                    <h4 className="card-title">
                        Ordenes de MercadoLibre / MercadoShops
                </h4>
                    <p className="card-description"> Resultados encontrados {orders.length} </p>
                    <p className="card-description" style={{ color: "#2196f3" }}> {this.state.seletedOrders.length} elementos seleccionados </p> 
                    

                    <Row className="float-right">
                        <Button color="success" className="ml-3" onClick={(evt: any) => this.search() }>
                            <i className="fa fa-refresh" />
                         </Button>
                    </Row>
                    <Row className="float-right">
                        <Col md={12}>
                            Mostrando {this.state.currentPage} de {Math.ceil(orders.length / this.itemsPerPage())}
                            <Button color="success" className="ml-2 mr-2" disabled={this.state.currentPage <= 1}
                                onClick={(evt: any) => this.setState({ currentPage: (this.state.currentPage - 1) })}>
                                {"<"}</Button>
                            <Button color="success" className="mr-2" disabled={this.state.currentPage >= ((orders.length / this.itemsPerPage()))}
                                onClick={(evt: any) => this.setState({ currentPage: (this.state.currentPage + 1) })}>
                                {">"}</Button>
                        </Col>
                    </Row>
                    <Row className="table-responsive">
                        <Col md={12}>
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th>
                                            <div className="form-check">
                                                <label className="form-check-label text-muted">
                                                    <input type="checkbox" className="form-check-input" checked={this.state.seletedOrders.length == this.props.orders.length} onChange={(evt: ChangeEvent) => this.togglerSelectedAllOrders()} />
                                                    <i className="input-helper"></i>
                                                </label>
                                            </div>
                                        </th>
                                        <th>Fecha</th>
                                        <th>Usuario-comprador</th>
                                        <th>Nro. Orden</th>
                                        <th>Forma de entrega</th>
                                        <th>Total</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.currentOrders().map((order: MeLiOrder, index: number) => {
                                        let orderDate = new Date(order.date);
                                        orderDate = moment(orderDate).add(orderDate.getTimezoneOffset(), 'm').toDate();
                                        return <tr key={index}>
                                            <td>
                                                <div className="form-check">
                                                    <label className="form-check-label text-muted">
                                                        <input type="checkbox" className="form-check-input" checked={this.state.seletedOrders.includes(order)} onChange={(evt: ChangeEvent) => this.pushSelectedOrder(order)} />
                                                        <i className="input-helper"></i>
                                                    </label>
                                                </div>
                                            </td>
                                            <td>{orderDate.getDate() + "/" + (orderDate.getMonth() + 1) + "/" + orderDate.getFullYear()}</td>
                                            <td>{order.userName}</td>
                                            <td>{order.orderNumber}</td>
                                            <td>{this.convertDeliveryType(order.deliveryType)}</td>
                                            <td>${this.getTotal(order).toFixed(2)}</td>
                                            <td>
                                                <span>
                                                    <Tooltip content="Ver detalle" position={Position.TOP}>
                                                        <a className="mr-2" target="_blank" href={`https://www.mercadolibre.com.ar/ventas/${order.orderNumber}/detalle`} >
                                                            <i className="fa fa-search"></i>
                                                        </a>
                                                    </Tooltip>
                                                </span></td>
                                        </tr>
                                    }
                                    )}
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                    <Row className="float-right pt-3">
                        <Col md={12}>
                            {!this.isInSale() ? <>
                                <Button disabled={this.state.seletedOrders.length == 0 || this.orderSelectedFromSameUsers()} onClick={(evt: any) => this.props.createMasiveSales(this.state.seletedOrders)} color="primary" className="mr-2" >Crear Ventas Masivas</Button>

                                <Button disabled={!this.orderSelectedFromSameUsers()} onClick={(evt: any) => this.props.createSale(this.state.seletedOrders)} color="primary" className="btn-primary mr-2" >Crear Venta</Button>
                                
                            </> :
                                this.props.currentSale != null ?
                                    <Button disabled={this.state.seletedOrders.length == 0} onClick={(evt: any) => this.props.addOrder(this.props.currentSale, this.state.seletedOrders)} color="success" className="btn-primary mr-2" >Agregar Ordenes</Button>
                                    :
                                    <></>
                            }
                            {this.isInSale() && this.props.currentSale != null ? <Button color="secondary" onClick={() => this.handleCancelClick()}>Cancelar</Button>
                                : <></>}
                            {/*BACK-214*/}
                            {
                                !this.isInSale() && this.props.user.role == "Admin" ? <>
                                    <Button disabled={this.state.seletedOrders.length == 0} onClick={(evt: any) => this.props.ruleOutMasiveSales(this.state.seletedOrders)} color="secondary" className="mr-2" >Descartar Ordenes</Button>
                                </> : <></>
                            }

                        </Col>
                    </Row>
                </Card.Body>
            </Card>
    }

    render() {
        return (
            <>
                {this.isInSale() && this.props.currentSale != null ? <h1>
                    Venta #<Link className="mr-2" to={`/ventas/detalle/${this.props.currentSale.id}`}>
                                {this.props.currentSale.id}
                            </Link>
                </h1> : <></>}
                <div className="grid-margin">
                    {this.renderHeader()}
                </div>
                <div className="grid-margin">
                    {this.renderBody()}
                </div>
                {this.popupDialog()}
                {this.confirmationSearchDialog()}
            </>
        )
    }
}

export default connect(
    (state: ApplicationState) => { return { ...state.userState, ...state.salesState } },
    (dispatch: any) => bindActionCreators({ ...commonActionCreators.actionCreators, ...salesActionCreators.actionCreators }, dispatch)
)(MLSale as any);

